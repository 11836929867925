import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import { SingleRowLogos } from '@bestfit-team/bf-base-ui';

const CollegesOrganizationsPilotPartners = () => {
  const copy = useStaticQuery(graphql`
    query {
      airtable(
        data: {Segment: {eq: "Colleges_Pilot Partners"}}
      ) {
        data {
          header
          status
          Images {
          data {
            attachments {
              url
            }
            section_order
            alt_text
            redirect_url
          }
        }
        }
      }
    }
  `);

  return (
    <SingleRowLogos
      version={'EducationOrganizations'}
      headingCopy={copy.airtable.data.header}
      imageData={copy.airtable.data.Images}

    />
  )
}

export default CollegesOrganizationsPilotPartners
