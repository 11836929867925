import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import { Hero } from '@bestfit-team/bf-base-ui';

const CollegesOrganizationsHero = () => {
  const copy = useStaticQuery(graphql`
    query CollegesOrganizationsHeroQuery {
      CollegesOrganizationsHeroInfo: airtable(
        data: {Segment: {eq: "Colleges_Header"}}, 
        ) {
          data {
            header
            status
            subheader
            action
            Segment
            action_url
            attached_images {
              filename
              url
            }
          }
      }
    }
  `);

console.log(JSON.stringify(copy))
  return (
    <>
      <Hero 
        heroBackgroundColor='var(--bf-op45-core-cream)'
        backgroundImageUrl={'https://best-fit.app/static/triangle-grey-25777a8336e851acd1255a9ef785a027.svg'}

        headingCopy={copy.CollegesOrganizationsHeroInfo.data.header}
        subheadingCopy={copy.CollegesOrganizationsHeroInfo.data.subheader}
        buttonLabel={copy.CollegesOrganizationsHeroInfo.data.action}
        buttonUrl={copy.CollegesOrganizationsHeroInfo.data.action_url}
        heroImageUrl={copy.CollegesOrganizationsHeroInfo.data.attached_images[0].url}
        heroImageAlt={copy.CollegesOrganizationsHeroInfo.data.attached_images[0].filename}
      />
    </>
  )
}

export default CollegesOrganizationsHero;
