import React from "react";
// import { graphql, useStaticQuery } from 'gatsby';
import { Heading, HorizontalDivider } from '@bestfit-team/bf-base-ui';
import Layout from "../components/layout";
import CollegesOrganizationsHero from "../sections/colleges-organizations/CollegesOrganizationsHero";
import CollegesOrganizationsImpact from "../sections/colleges-organizations/CollegesOrganizationsImpact";
import CollegesOrganizationsContactUs from "../sections/colleges-organizations/CollegesOrganizationsContactUs";
import CollegesOrganizationsPilotPartners from "../sections/colleges-organizations/CollegesOrganizationsPilotPartners";

// TODO: #13 ForCollegesOrgs - pilot partners
// TODO: #15 ForCollegesOrgs - stats
// TODO: #16 ForCollegesOrgs - how it works
// TODO: #17 ForCollegesOrgs - resource list
// TODO: #18 ForCollegesOrgs - partner now
// TODO: #43 ForCollegesOrgs - top-margin for header component [no whitespace above pic]
const ForCollegesOrganizations = ({ data }) => {
  console.log(data)
  return (
    <Layout
      pageTitle='For Colleges Organizations'
    >
      <CollegesOrganizationsHero />

      <CollegesOrganizationsPilotPartners />

      <CollegesOrganizationsImpact /> 
      
      <Heading h2> demographics </Heading>
      <HorizontalDivider />
      <Heading h2> how it works </Heading>
      <HorizontalDivider />
      <Heading h2> resource list </Heading>
      <HorizontalDivider />
      {/* <Heading h2> partner now </Heading> */}
      <CollegesOrganizationsContactUs />
      <HorizontalDivider />
      <Heading h2> footer </Heading>
    </Layout>
  )
}

// export const query = graphql`
//   query ForCollegesOrganizations {
    
//   }
// `

export default ForCollegesOrganizations;