import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import { SingleColumnImpact } from '@bestfit-team/bf-base-ui';

const CollegesOrganizationsImpact = ({ ...props }) => {
  const copy = useStaticQuery(graphql`
    query CollegesOrganizationsImpactQuery {
      CollegesOrganizationsImpactContent: airtable(data: {Segment: {eq: "Colleges_Impact"}}) {
      data {
        header
        attached_images {
          url
        }
      }
    }
  }
`);
//TODO: #50 fix the fact that options are no longer columns
console.log(JSON.stringify(copy))
  return (
    <>
      <SingleColumnImpact
        impactCopy={copy.CollegesOrganizationsImpactContent.data.header}
      />
    </>
  )
}

export default CollegesOrganizationsImpact;
