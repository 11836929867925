import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import { ContactUsForm } from '@bestfit-team/bf-base-ui';

const CollegesOrganizationsContactUs = () => {
  const copy = useStaticQuery(graphql`
    query {
      airtable(
        data: {Segment: {eq: "Partner Now Form"}}
      ) {
        data {
          header
          subheader
          status
          action_url
          alt_text
        }
      }
    }
  `);

  return (
    <ContactUsForm
      headingCopy={copy.airtable.data.header}
      subheadingCopy={copy.airtable.data.subheader}
      embedUrl={copy.airtable.data.action_url}
      embedTitle={copy.airtable.data.alt_text}
    />
  )
}

export default CollegesOrganizationsContactUs
